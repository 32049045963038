import common from '../element/mixins';
import {
  getFormTypeListByConfigId,
} from '@/api/formSetting';
import {
  getCustomQueryConditions,
  getCustomQueryColumn,
} from '@/api/commonForm';
import { flatternFields } from '../../utils/component';
import { cloneDeep } from 'lodash';
import FilterDialog from './FilterDialog';
import FieldFillDialog from './FieldFillDialog';
import VirtualSelector from '@/components/common/virtualSelector';
export default {
  props: {
    activeData: Object,
  },
  inject: ['getFieldList'],
  data () {
    return {
      templateList: [],
      relatedFormQueryFields: [],
      relatedFormFieldList: [],
      sumFields: [],
      filterDialogVisible: false,
      fillDialogVisible: false,
    };
  },
  components: {
    FilterDialog,
    FieldFillDialog,
    VirtualSelector,
  },
  computed: {
    currentProcessFieldList () {
      let filedMap = {};
      const fields = this.filterListFields(this.getFieldList() || []);
      flatternFields(fields || [], filedMap);
      return Object.keys(filedMap).map(t=>filedMap[t]);
    },
    isOnlyRelatedQuery () {
      // null、0为关联查询
      // 1为关联引用
      return this.activeData.releatedType !== 1;
    },
  },
  methods: {
    filterListFields (fields) {
      let ret = cloneDeep(fields);
      ret.forEach(t=>{
        let children = t.__config__.children;
        for (let index = children.length - 1; index >= 0; index--) {
          const element = children[index];
          if (['tableColumnFormItem', 'childFormItem'].indexOf(element.__config__.layout) >= 0) {
            children.splice(index, 1);
          }
        }
      });
      return ret;
    },

    getTemplateColumns () {
      // 查询表头作为统计字段
      getCustomQueryColumn({
        customizeSystemConfigCode: this.activeData?.relatedFormType,
        customizeBusinessTypeCode: this.activeData?.relatedFormTemplateCode,
      }).then(res=>{
        this.relatedFormFieldList = res.body || [];
        this.sumFields = res.body?.filter(t=>t.columnType === 'el-input-number') || [];
      });
    },
    getTemplateConditions () {
      // 查询条件作为关联条件
      return getCustomQueryConditions({
        customizeSystemConfigCode: this.activeData?.relatedFormType,
        customizeBusinessTypeCode: this.activeData?.relatedFormTemplateCode,
      }).then(res=>{
        this.relatedFormQueryFields = res.body || [];
      });
    },
    handleTemplateChanged (selectedTemplate) {
      // 同时记录对应模板的code
      // const selectedTemplate = this.templateList.find(t=>t.id === templateId);
      this.activeData.relatedFormTemplateCode = selectedTemplate?.code;
      this.activeData.relatedFormTemplateName = selectedTemplate?.name;
      this.activeData.relatedFormType = selectedTemplate?.customizeSystemConfigCode;
      if (selectedTemplate) {
        this.getTemplateConditions().then(()=>{
          this.$set(this.activeData, 'relatedFormFillField', null);
        });
        this.getTemplateColumns();
      } else {
        this.relatedFormQueryFields.splice(0, this.relatedFormQueryFields.length);
        this.$set(this.activeData, 'relatedFormFillField', null);
      }
    },
    handleResetFilters () {
      this.activeData.relatedFormFillField = null;
      this.activeData.relatedFormQueryBy = null;
      this.activeData.filters = null;
    },
  },
  mixins: [common],
  render () {
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="显示标题" >
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
        <el-divider>{this.isOnlyRelatedQuery ? '设置关联查询' : '设置关联引用'}</el-divider>
        <el-form-item label={this.isOnlyRelatedQuery ? '查询单据' : '引用单据'} >
          <virtual-selector
            placeholder={this.isOnlyRelatedQuery ? '请选择需要查询单据' : '请选择需要引用的单据'}
            v-model={this.activeData.relatedFormTemplateId}
            name={this.activeData.relatedFormTemplateName}
            optionList={this.templateList}
            filterable
            option={{
              label: 'name',
              key: 'id',
            }}
            onChange={this.handleTemplateChanged}
            size="small">
          </virtual-selector>
        </el-form-item>
        {this.isOnlyRelatedQuery ? null
          : <el-form-item label="允许多选" >
            <el-switch v-model={this.activeData.allowMultipleSelect} onChange={()=>{ this.activeData.fillFields = []; }}/>
          </el-form-item>
        }
        <el-form-item label="允许新增" >
          <el-switch v-model={this.activeData.allowCreate}/>
        </el-form-item>
        {
          this.isOnlyRelatedQuery || this.activeData.allowMultipleSelect ? null : <el-form-item label="数据填充">
            <el-button type="primary" plain onClick={()=>{ this.fillDialogVisible = true; }}>设置填充规则{this.activeData.fillFields && this.activeData.fillFields[0] ? `(${this.activeData.fillFields.length})` : ''}</el-button>
          </el-form-item>
        }
        {
          this.isOnlyRelatedQuery ? null : <el-form-item label="数据过滤">
            <el-button type="primary" plain onClick={()=>{ this.filterDialogVisible = true; }}>设置过滤条件{this.activeData.filters && this.activeData.filters[0] ? `(${this.activeData.filters.length})` : ''}</el-button>
          </el-form-item>
        }
        {
          this.isOnlyRelatedQuery ? <el-form-item label="查询条件">
            <el-radio-group v-model={this.activeData.queryType} onChange={this.handleResetFilters}>
              <el-radio-button label="custom">引用条件</el-radio-button>
              <el-radio-button label={undefined}>自定义条件</el-radio-button>
            </el-radio-group>
          </el-form-item> : null
        }
        {
          this.activeData.queryType === undefined && this.isOnlyRelatedQuery
            ? <el-form-item >
              <el-button type="primary" plain onClick={()=>{ this.filterDialogVisible = true; }}>设置查询条件{this.activeData.filters && this.activeData.filters[0] ? `(${this.activeData.filters.length})` : ''}</el-button>
            </el-form-item>
            : null
        }
        {
          this.filterDialogVisible ? <FilterDialog v-model={this.activeData.filters}
            title={this.isOnlyRelatedQuery ? '数据查询条件' : '数据过滤条件'}
            sourceFieldList={this.relatedFormQueryFields}
            bindFiledList={this.currentProcessFieldList}
            onClose={()=>{ this.filterDialogVisible = false; }}/> : null
        }
        {
          this.fillDialogVisible ? <FieldFillDialog v-model={this.activeData.fillFields}
            sourceFieldList={this.relatedFormFieldList}
            bindFiledList={this.currentProcessFieldList}
            onClose={()=>{ this.fillDialogVisible = false; }}/> : null
        }
      </div>
    );
  },
  created () {
    getFormTypeListByConfigId().then(res=>{
      this.templateList = (res.body || []).filter(i => !!i.status);
    });

    if (this.activeData.relatedFormTemplateId) {
      this.getTemplateConditions();
      this.getTemplateColumns();
    }
    if (!this.activeData.sumBy) {
      this.$set(this.activeData, 'sumBy', []);
    }
    if (this.activeData.relatedFormFillField && this.activeData.relatedFormQueryBy) {
      this.activeData.filters = [{
        relatedFormFillField: this.activeData.relatedFormFillField,
        relatedFormQueryBy: this.activeData.relatedFormQueryBy,
      }];
      this.activeData.relatedFormFillField = undefined;
      this.activeData.relatedFormQueryBy = undefined;
    }
  },
};
