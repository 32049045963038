import server from '../request';

export default {
  getProductList (data) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/page', {
      ...data,
      orderBy: [
        {
          orderByProperty: data.orderByProperty || 'lastUpdateTime',
          orderByType: data.orderByType || 'DESC',
        },
      ],
    });
  },
  getSynergyProductList (data) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/collaborativeTicketsList', {
      ...data,
      orderBy: [
        {
          orderByProperty: data.orderByProperty || 'lastUpdateTime',
          orderByType: data.orderByType || 'DESC',
        },
      ],
    });
  },

  summary () {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/count');
  },

  assign (orderList) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/designate', orderList);
  },
  saveOrder (params) {
    if (params.id) {
      return server.post('/xiaozi-xmb/wireless/saas/workOrder/update', params);
    } else {
      return server.post('/xiaozi-xmb/wireless/saas/workOrder/add', params);
    }
  },
  saveAndAssignOrder (params) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/designateAndSave', params);
  },
  deleteOrders (orderList) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/delete', orderList);
  },
  detail (id) {
    let formData = new FormData();
    formData.append('id', id);
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/detail', formData,
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      },
    );
  },

  detailList (id) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/printDetails', {id}, {
      responseType: 'blob',
    });
  },

  cancelOrders (orderList) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/cancel', orderList);
  },
  closeOrders (orderList) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/close', orderList);
  },
  acceptOrRejectOrder (data) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/orders', data);
  },
  // 查询工单类型列表
  getFormTypeListByConfigId (data) {
    return server.post('/xiaozi-xmb/wireless/saas/customize/businessType/queryAll', data);
  },
  // 导出工单
  exportWorkOrderExcel (params) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/exportWorkOrderExcel', params, {
      responseType: 'blob',
    });
  },
  //  设备服务能力 分页列表
  deviceServicePage (params) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/deviceServicePage', params);
  },
  //  设备服务能力 导出
  exportDeviceServiceExcel (params) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/exportDeviceServiceExcel', params, {
      responseType: 'blob',
    });
  },
  // 获取员工 动态信息 分页列表
  getDynamicInfoForPageByEmployeeId (params) {
    return server.post('/xiaozi-xmb/wireless/saas/dynamicInfo/dynamicInforPageByEmployeeId', params);
  },
  // 导出-员工：设备服务能力
  exportDynamicInfoExcel (params) {
    return server.post('/xiaozi-xmb/wireless/saas/dynamicInfo/exportDynamicInfoExcel', params, {
      responseType: 'blob',
    });
  },
  importWorkOrder (params) {
    let config = arguments[1] || {};
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/bulkAdd', params, config);
  },
  // 单据进度
  orderProgress (data) {
    return server.post('/xiaozi-xmb/wireless/saas/customize/process/queryAllCheckNodeByTypeId', data);
  },
  // 指派工单给服务商
  designateToService (data) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/designateToService', data);
  },
  // 服务商列表接口
  getServiceProvider (data, config) {
    return server.post('/xiaozi-xmb/wireless/saas/serviceProvider/page', data, config);
  },

  // 统计订单数量
  orderCount (data) {
    return server.post('/xiaozi-xmb/wireless/saas/order/count', data);
  },

  // 工单独立状态计数
  statusStastic (data) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/countStatus', data);
  },
  // 修改关单时间
  updateCloseTime (data) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/updateCloseTime', data);
  },
};
