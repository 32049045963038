export default [
  {
    value: 'WORKING_DAY',
    name: '工作日 ',
  },
  {
    value: 'WEEKEND',
    name: '双休日',
  },
  {
    value: 'ANY_TIME',
    name: '随时',
  },
];
