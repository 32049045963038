<script>
import ComponentSelector from '../common/componentSelector.vue';
import common from '../element/mixins';
import itemFieldsMap from '../itemFields';
import * as components from '../../components';
export default {
  mixins: [common],
  components: {
    ComponentSelector,
    ...components,
  },
  methods: {
    handleFilterChange (filter, item) {
      this.$set(this.activeData.__config__.filterParam[item], 'source', filter);
    },
    handleDataFilter (filter) {
      this.$set(this.activeData.__config__, 'from', filter);
    },
  },
  computed: {
    defaultValueSettingVisible () {
      return this.activeData.__config__.tag === 'c-user-selector'
      || this.activeData.__config__.tag === 'c-department-selector';
    },
    defaultValueOptions () {
      switch (this.activeData.__config__.tag) {
      case 'c-user-selector':
        return [{
          label: '当前登录人',
          value: '${currentUser}',
        }];
      case 'c-department-selector':
        return [{
          label: '当前登录人部门',
          value: '${currentDepartment}',
        }];
      default:
        return [];
      }
    },
  },
  props: {
    activeData: Object,
  },
  render (h) {
    if (this.activeData?.__config__?.defaultValue === undefined) {
      this.$set(this.activeData.__config__, 'defaultValue', null);
    }
    let filterPanel = [];
    let componentFilter = t => {
      return t.__config__.renderKey !== this.activeData.__config__.renderKey
          && t.__config__.tag === this.activeData.__config__.tag;
    };
    if (this.activeData.__config__.filterParam) {
      filterPanel.push(<el-divider>数据筛选</el-divider>);
      Object.keys(this.activeData.__config__.filterParam).forEach((item, index) => {
        filterPanel.push(
          <el-form-item label={this.activeData.__config__.filterParam[item].desc} key={index}>
            <el-row>
              <el-col span={4}>=</el-col>
              <el-col span={20}>
                <component-selector
                  value={this.activeData.__config__.filterParam[item].source}
                  onChange={filter => this.handleFilterChange(filter, item)}
                  componentFilter={['el-input', 'el-input-number', 'span']}
                  fieldFilter={['el-input', 'el-input-number', 'span']}
                />
              </el-col>
            </el-row>
          </el-form-item>,
        );
      });
    }
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="显示标题">
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="占位提示">
          <el-input
            value={this.activeData.placeholder}
            placeholder="请输入占位提示"
            onInput={val => {
              this.$set(this.activeData, 'placeholder', val);
            }}
          />
        </el-form-item>
        <el-form-item label="表单栅格">
          <el-radio-group class="fixed" v-model={this.activeData.__config__.span}>
            <el-radio-button label={6}></el-radio-button>
            <el-radio-button label={8}></el-radio-button>
            <el-radio-button label={12}></el-radio-button>
            <el-radio-button label={24}></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="默认值">
          <el-radio-group v-model={this.activeData.__config__.isFixedValue} onChange={this.handleDefaultValueTypeChange}>
            <el-radio-button label={1}>固定</el-radio-button>
            <el-radio-button label={0}>动态</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item >
          {
            this.activeData.__config__.isFixedValue !== undefined
              ? this.activeData.__config__.isFixedValue
                ? this.defaultValueSettingVisible
                  ? <el-radio-group v-model={this.activeData.__config__.defaultValue}>
                    {
                      this.defaultValueOptions.map(t=><el-radio label={t.value}>{t.label}</el-radio>)
                    }
                  </el-radio-group>
                  : h(this.activeData.__config__.tag, {props: {...this.activeData, value: this.activeData.__config__.defaultValue}, on: {input: (val)=>{ this.activeData.__config__.defaultValue = val; }}})
                : <component-selector componentFilter={componentFilter}
                  current={this.activeData}
                  value={this.activeData.__config__.from}
                  onChange={this.handleDataFilter}/>
              : null
          }
        </el-form-item>
        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
        {...filterPanel}
        <el-divider>可关联字段</el-divider>
        {itemFieldsMap[this.activeData.__config__.tag]
          ? itemFieldsMap[this.activeData.__config__.tag].map((item, index) => {
            return (
              <div label="" key={index} class="available-field-item">
                {item.desc}
              </div>
            );
          })
          : null}
      </div>
    );
  },
};
</script>
<style scoped>
.available-field-item {
  font-size: 14px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
