
import common from './element/mixins';
import { getSysConfigList } from '@/api/formSetting';
export default {
  props: {
    activeData: Object,
  },
  data () {
    return {
      businessTypeList: [],
    };
  },
  mixins: [common],
  render () {
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="显示标题" >
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="占位提示">
          <el-input
            value={this.activeData.placeholder}
            placeholder="请输入占位提示"
            onInput={val => {
              this.$set(this.activeData, 'placeholder', val);
            }}
          />
        </el-form-item>
        <el-form-item label="表单栅格">
          <el-radio-group class="fixed" v-model={this.activeData.__config__.span}>
            <el-radio-button label={6}></el-radio-button>
            <el-radio-button label={8}></el-radio-button>
            <el-radio-button label={12}></el-radio-button>
            <el-radio-button label={24}></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
        <el-form-item label="单据类型" >
          <el-select
            v-model={this.activeData.relatedFormType}
            placeholder="请选择单据类型"
            style={{ width: '100%' }}
          >
            {
              this.businessTypeList.map(t=>{
                return <el-option key={t.id} label={t.name} value={t.code}/>;
              })
            }
          </el-select>
        </el-form-item>
      </div>
    );
  },
  created () {
    getSysConfigList().then(res=>{
      this.businessTypeList = res.body;
    });
  },
};
