<template>
  <div class="image_viewer" v-show="previewDialogVisile" :style="{zIndex:99999}">
      <div class="image_viewer_mask" ></div>
      <span class="image-viewer__btn image-viewer__close" @click="handleClose">
        <i class="iconfont icon-guanbi"></i>
      </span>
      <template v-if="!isSingle">
        <span
          class="image-viewer__btn image-viewer__prev"
          :class="{ 'disabled':  isFirst }"
          @click="handlePerviewPageChange('pre')">
          <i class="iconfont icon-zuofanye"/>
        </span>
        <span
          class="image-viewer__btn image-viewer__next"
          :class="{ 'disabled': isLast }"
          @click="handlePerviewPageChange('next')">
          <i class="iconfont icon-youfanye"/>
        </span>
      </template>
      <div class="image_viewer_container">
        <span>{{currentIndex+1}}/{{fileList.length}}</span>
        <el-divider direction="vertical"></el-divider>
        <div>
          <i :class="`iconfont icon-fangda ${isPreviewImage?'':'disabled'}` " @click="handleActions('zoomOut')" title="放大"></i>
          <i :class="`iconfont icon-suoxiao ${isPreviewImage?'':'disabled'}`" @click="handleActions('zoomIn')" title="缩小"></i>
          <i :class="`iconfont icon-huanyuan ${isPreviewImage?'':'disabled'}`" @click="handleActions('reset')" title="还原"></i>
          <i :class="`iconfont icon-shuaxin ${isPreviewImage?'':'disabled'}`" @click="handleActions('clocelise')" title="旋转"></i>
          <i  class="iconfont icon-xiazai" @click="handleActions('download')" title="下载"/>
        </div>
        <el-divider direction="vertical"></el-divider>
        <span><i  class="iconfont icon-piliangxiazai" @click="handleActions('patchdownload')" title="打包下载"/></span>
      </div>
      <div class="image-viewer__canvas"
        v-loading="isLoading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        v-if="previewDialogVisile">
        <img v-if="currentFileType=== 'IMAGE'"
          ref="img"
          class="image-viewer__img"
          :src="currentImageSrc"
          @load="handleImgLoad"
          @error="handleImgError"
          @mousedown="handleMouseDown"
          :style="imgStyle">
        <div v-else class="image-viewer__file">
          <img :src="getFileCover()" alt="">
          <span>该文件暂不支持预览，请下载后查看</span>
          <el-link type="primary" @click="handleActions('download')">点击下载</el-link>
        </div>
      </div>
    </div>
</template>
<script>
import _ from 'lodash';
import { downloadAndZip, downloadFile } from '@/utils/download';
import { PopupManager } from 'element-ui/src/utils/popup';
export default {
  name: 'image_previewer',
  props: {
    fileList: {
      type: Array,
      default: ()=>[],
    },
    initialIndex: {
      type: Number,
      default: 0,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    viewerZIndex () {
      return PopupManager.nextZIndex();
    },
    isFirst () {
      return this.currentIndex === 0;
    },
    isLast () {
      return this.currentIndex === this.fileList.length - 1;
    },
    isSingle () {
      return this.fileList.length === 1;
    },
    imgStyle () {
      const { scale, deg, offsetX, offsetY, enableTransition } = this.transform;
      const style = {
        transform: `scale(${scale}) rotate(${deg}deg)`,
        transition: enableTransition ? 'transform .3s' : '',
        'margin-left': `${offsetX}px`,
        'margin-top': `${offsetY}px`,
      };
      // if (this.mode === Mode.CONTAIN) {
      //   style.maxWidth = style.maxHeight = '100%';
      // }
      return style;
    },
    currentImageSrc () {
      return this.fileList[this.currentIndex]?.url;
    },
    currentFileType () {
      let currentImage = this.fileList[this.currentIndex];
      if (currentImage) {
        if (currentImage.type && currentImage.type.indexOf('image') >= 0) {
          return 'IMAGE';
        }
        let spl = currentImage.url.split('.');
        const fileType = spl[spl.length - 1];
        switch (fileType.toUpperCase()) {
        case 'PNG':
        case 'JPEG':
        case 'JPG':
        case 'GIF':
        case 'BMP':
          return 'IMAGE';
        case 'PDF':
          return 'PDF';
        case 'DOC':
        case 'DOCX':
          return 'DOC';
        case 'XLS':
        case 'XLSX':
          return 'XLS';
        case 'PPT':
        case 'PPTX':
          return 'PPT';
        default:
          return 'common';
        }
      }
      return null;
    },
    isPreviewImage () {
      return this.currentFileType === 'IMAGE';
    },
  },
  watch: {
    visible () {
      this.previewDialogVisile = this.visible;
      if (!this.visible) {
        // 关闭重置当前预览索引
        this.currentIndex = 0;
        this.resetTransform();
      } else {
        // 打开显示加载进度
        this.isLoading = this.currentFileType === 'IMAGE';
      }
    },
  },
  data () {
    return {
      previewDialogVisile: this.visible,
      currentIndex: this.initialIndex,
      transform: {
        scale: 1,
        deg: 0,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false,
      },
      isLoading: false,
    };
  },
  methods: {
    getFileCover () {
      const fileType = this.currentFileType;
      switch (fileType) {
      case 'PDF':
        return require('../../assets/imgs/office/preview_pdf.png');
      case 'DOC':
        return require('../../assets/imgs/office/preview_doc.png');
      case 'PPT':
        return require('../../assets/imgs/office/preview_ppt.png');
      case 'XLS':
        return require('../../assets/imgs/office/preview_excel.png');
      default:
        return require('../../assets/imgs/office/preview_common.png');
      }
    },
    handleActions (action, options = {}) {
      if (this.loading) return;
      const { zoomRate, rotateDeg, enableTransition } = {
        zoomRate: 0.2,
        rotateDeg: 90,
        enableTransition: true,
        ...options,
      };
      const { transform } = this;
      switch (action) {
      case 'zoomIn':
        if (!this.isPreviewImage) {
          return;
        }
        if (transform.scale > 0.2) {
          transform.scale = parseFloat((transform.scale - zoomRate).toFixed(3));
        }
        break;
      case 'zoomOut':
        if (!this.isPreviewImage) {
          return;
        }
        transform.scale = parseFloat((transform.scale + zoomRate).toFixed(3));
        break;
      case 'reset':
        if (!this.isPreviewImage) {
          return;
        }
        this.resetTransform();
        break;
      case 'clocelise':
        if (!this.isPreviewImage) {
          return;
        }
        transform.deg += rotateDeg;
        break;
      case 'download':
        downloadFile(this.currentImageSrc);
        return;
      case 'patchdownload':
        downloadAndZip(this.fileList).then(()=>this.$message.success('下载成功'));
        return;
      default:
        return;
      }
      transform.enableTransition = enableTransition;
    },
    resetTransform () {
      this.transform = {
        scale: 1,
        deg: 0,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false,
      };
    },
    handleClose () {
      this.previewDialogVisile = false;
      this.$emit('visible:update', false);
      this.$emit('close');
    },
    handlePerviewPageChange (action) {
      if (action === 'pre') {
        if (this.currentIndex <= 0) return;
        this.currentIndex--;
      } else {
        if (this.currentIndex === this.fileList.length - 1) return;
        this.currentIndex++;
      }
      this.resetTransform();
      this.isLoading = this.currentFileType === 'IMAGE';
    },
    handleImgLoad () {
      this.isLoading = false;
    },
    handleImgError (e) {
      this.isLoading = false;
      e.target.alt = '加载失败';
    },
    handleMouseDown (e) {
      if (this.isLoading || e.button !== 0) return;

      const { offsetX, offsetY } = this.transform;
      const startX = e.pageX;
      const startY = e.pageY;
      this._dragHandler = _.throttle(ev => {
        this.transform.offsetX = offsetX + ev.pageX - startX;
        this.transform.offsetY = offsetY + ev.pageY - startY;
      });
      window.document.addEventListener('mousemove', this._dragHandler);
      window.document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove', this._dragHandler);
      });

      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.image_viewer {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  .image_viewer_mask {
    height: 100%;
    width: 100%;
    opacity: .5;
    background:rgba(0,0,0,0.9);
    position: absolute;
  }

  .image-viewer__close {
    position: absolute;
    top: 36px;
    right: 60px;
  }

  .image-viewer__btn {
    color: white;
    z-index: 2;
    cursor: pointer;
    padding:4px;
    &>.iconfont {
      font-size: 40px;
    }
    &:hover{
      background: rgba(31, 27, 27, 0.5);
      border-radius: 4px;
    }

    &.disabled {
        color: rgb(92, 93, 95);
        cursor: not-allowed;
      }
  }

  .image-viewer__prev {
    position: absolute;
    left: 60px;
    top:50%;
    transform: translateY(-50%);
  }

  .image-viewer__next {
    position: absolute;
    right: 60px;
    top:50%;
    transform: translateY(-50%);
  }

  .image_viewer_container{
    flex: 0;
    position: fixed;
    left: 0;
    bottom: 12px;
    right: 0;
    text-align: center;
    z-index: 2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    overflow: hidden;
    background: rgba(255,255,255,0.7);
    width: 340px;
    margin:0 auto;
    padding:0px 12px;
    box-sizing: border-box;
    /deep/.el-divider {
      background: #242832;
      margin-left: 4px;
      margin-right: 4px;
    }
    i {
      font-size: 20px;
      padding:10px 10px;
      &.disabled {
        color: #A7A9AD;
        cursor: not-allowed;
      }
      cursor: pointer;
      &:hover{
        background: rgba(31, 27, 27, 0.5);
        border-radius: 4px;
      }
    }

    &>span{
      flex:1;
        display: flex;
        justify-content: center;

      &:nth-child(1){

        font-size: 14px;
        font-weight: 400;
        color: #242832;
      }
    }

    &>div:nth-child(3) {
      display: flex;
      align-items: center;
      // width: 200px;
      &>i {
        flex: 1;
      }
    }
  }

  .image-viewer__canvas {
    width: 100%;
    margin-top: 36px;
    height: calc(100% - 36px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

    &>img.image-viewer__img {
      max-height: 100%;
      max-width: 100%;
      cursor: grab;
    }

    &>.image-viewer__file {
      width: 400px;
      height: 318px;
      background: #FFFFFF;
      box-shadow: 0px 4px 6px 0px rgba(36,40,50,0.08);
      border-radius: 8px;
      box-sizing: border-box;
      padding:60px 0 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: rgba(36,40,50,0.7);

      &>img{
        width: 120px;
      }
    }
  }
}
</style>
