<script>
import Clickoutside from 'element-ui/src/utils/clickoutside';
import itemFieldsMap from '../itemFields';

export default {
  name: 'generator-component-selector',
  props: {
    current: {
      type: Object,
      validator: (val)=>{
        return val?.__config__?.renderKey && val?.__config__.tag;
      },
    },
    fieldFilter: {
      type: [Array, Function],
    },
    componentFilter: {
      type: [Array, Function],
    },
    value: Object,
    placeholder: {
      type: String,
      default: '请选择',
    },
  },
  directives: { Clickoutside },
  data () {
    return {
      visible: false,
      categoryCacheMap: {},
      width: 100,
      componentName: this.name,
      keyword: '',
      currentNodeKey: '',
    };
  },
  watch: {
    keyword (val) {
      this.$refs.componentTree.filter(val);
    },
    value () {
      if (this.value) {
        this.currentNodeKey = this.setCurrentKey();
        this.getChianName(this.currentNodeKey);
      }
    },
    name (val = '') {
      this.componentName = val;
    },
    targetLists () {
      if (this.value) {
        this.currentNodeKey = this.setCurrentKey();
        this.getChianName(this.currentNodeKey);
      } else {
        this.$emit('update:name', '');
        this.$emit('change', null);
        this.componentName = '';
      }
    },
  },
  inject: ['getFieldList'],
  methods: {
    setCurrentKey () {
      let ret = [];
      this.value?.key && ret.push(this.value?.key);
      this.value?.prop && ret.push(this.value?.prop);
      return ret.join('-');
    },
    filterNode (value, data) {
      if (!value) {
        return true;
      }
      let label = data.name;
      return label.indexOf(value) !== -1;
    },
    handleFocus () {
      if (this.readonly) return;
      this.visible = !this.visible;
    },
    handleNodeClick (data) {
      if (data.layout !== 'groupRowFormItem' && (!data.children || !data.children[0])) {
        this.getChianName(data.id);
        this.$emit('update:name', this.componentName);
        this.$emit('change', { key: data.id.split('-')[0], prop: data.field });
        this.visible = false;
      }
    },
    getChianName (nodeId) {
      let selectedDepartmentList = [];
      while (nodeId) {
        let node = this.categoryCacheMap[nodeId];
        if (node) {
          selectedDepartmentList.unshift(node);
          nodeId = node.pid;
        } else {
          nodeId = '';
        }
      }
      this.componentName = selectedDepartmentList.map(t => t.name).join('.');
    },
    handleClose () {
      this.visible = false;
      this.keyword = '';
    },
    calcPopoverWidth () {
      let rect = this.$refs.input.$el.getBoundingClientRect();
      this.width = rect.width;
    },
    cacheNode (sources) {
      sources.forEach(c => {
        this.categoryCacheMap[c.id] = c;
        if (c.children) {
          this.cacheNode(c.children);
        }
      });
    },
    handlerClear () {
      this.$emit('update:name', '');
      this.$emit('change', null);
      this.componentName = '';
    },
  },
  mounted () {
    this.calcPopoverWidth();
    this.popperElm = this.$refs.componentTreePopover;
    window.addEventListener('resize', this.calcPopoverWidth);
    if (this.value) {
      this.currentNodeKey = this.setCurrentKey();
      this.getChianName(this.currentNodeKey);
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calcPopoverWidth);
  },
  computed: {
    targetLists () {
      const converToTree = (nodes, parent, componentFilter, fieldFilter)=>{
        if (!nodes) {
          return [];
        }
        let rets = [];
        for (let index = 0; index < nodes.length; index++) {
          const t = nodes[index];
          let temp = {
            id: t.__config__.renderKey,
            name: t.__config__.label,
            layout: t.__config__.layout,
            pid: parent?.__config__?.renderKey,
          };
          if (t.__config__?.layout === 'tableColumnFormItem' || t.__config__?.layout === 'childFormItem') {
            continue;
          } else if (t.__config__.layout === 'groupRowFormItem') {
            temp.children = converToTree(t?.__config__.children, t, componentFilter, fieldFilter);
            if (temp.children[0]) {
              rets.push(temp);
            }
          } else {
            temp.raw = t;
            if (componentFilter(t)) {
              rets.push(temp);
            } else {
              if (itemFieldsMap[t.__config__.tag]) {
                temp.children = itemFieldsMap[t.__config__.tag].filter(fieldFilter).map(m => ({
                  id: t.__config__.renderKey + '-' + m.field,
                  name: m.desc,
                  field: m.field,
                  isBindFiled: true,
                  pid: t.__config__.renderKey,
                  raw: {
                    __config__: {
                      tag: m.type,
                    },
                  },
                }));
                if (temp.children[0]) {
                  rets.push(temp);
                }
              }
            }
          }
        }
        return rets;
      };
      const componentFilter = t=>{
        if (this.componentFilter) {
          if (this.componentFilter instanceof Array) {
            return this.componentFilter.indexOf(t?.__config__?.tag) >= 0;
          }
          return this.componentFilter(t);
        } else {
          return this.current?.__config__.tag === t?.__config__?.tag && this.current?.__config__.renderKey !== t?.__config__?.tag;
        }
      };
      const fieldFilter = t=>{
        if (this.fieldFilter) {
          if (this.fieldFilter instanceof Array) {
            return this.fieldFilter.indexOf(t?.type) >= 0;
          }

          return this.fieldFilter(t);
        } else {
          const { type, typeProps } = t;
          if (typeProps) {
            // 属性值要一致
            const propsCompareList = Object.keys(typeProps).map(t=>{
              return typeProps[t] === this.current[t];
            });
            return type === this.current?.__config__.tag && propsCompareList.every(t=>t);
          } else {
            return type === this.current?.__config__.tag;
          }
        }
      };
      const rets = converToTree(this.getFieldList(), null, componentFilter, fieldFilter);
      this.cacheNode(rets);
      return rets || [];
    },
  },
  render () {
    return (
      <el-popover
        popper-class="component_tree_selector"
        placement="bottom-start"
        trigger="manual"
        visible-arrow={false}
        width={this.width}
        v-clickoutside={this.handleClose}
        v-model={this.visible}
      >
        <el-input
          ref="input"
          placeholder={this.placeholder}
          slot="reference"
          value={this.componentName}
          on-focus={this.handleFocus}
          readonly
          clearable
        >
          <i class="el-icon-close el-input__icon" slot="suffix" onClick={this.handlerClear}></i>
        </el-input>
        <div ref="componentTreePopover">
          <el-input placeholder="请选择" size="small" v-model={this.keyword}></el-input>
          <el-tree
            {...{
              attrs: {
                props: {
                  label: data => data.name,
                  children: 'children',
                  isLeaf: data => !data.children || !data.children[0],
                },
                data: this.targetLists,
                nodeKey: 'id',
                'current-node-key': this.currentNodeKey,
                'filter-node-method': this.filterNode,
              },
            }}
            ref="componentTree"
            on-node-click={this.handleNodeClick}
          />
        </div>
      </el-popover>
    );
  },
  created () {
    if (this.value) {
      this.currentNodeKey = this.setCurrentKey();
    }
  },
};
</script>

<style lang="scss">
.component_tree_selector {

  .el-tree {
    padding-top: 8px;
    max-height: 300px;
    overflow: auto;
  }
}
</style>
