import fileSaver from 'file-saver';
import { fromBlob } from 'file-type/browser';
import JSZip from 'jszip';

export default ({ data, type, fileTitle })=> {
  let blob = new Blob([data], { type });
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = fileTitle;
  document.querySelectorAll('body')[0].appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export async function zipFiles (files, zipName) {
  const zip = new JSZip();
  files.forEach((item) => {
    zip.file(item.name, item.blob, { binary: true });
  });
  const content = await zip.generateAsync({ type: 'blob' });
  const currentDate = new Date().getTime();
  const fileName = zipName ? `${zipName}.zip` : `zipped-${currentDate}.zip`;
  return fileSaver.saveAs(content, fileName);
}
export async function downloadFile (url) {
  return fetch(url).then(async (res) => {
    const decodeUrl = decodeURIComponent(url);
    const blob = await res.blob();
    let fileName = decodeUrl.substring(decodeUrl.lastIndexOf('/') + 1, url.length);
    if (fileName.indexOf('.') < 0) {
      const fileType = await fromBlob(blob);
      fileName = `${fileName}.${fileType.ext}`;
    }
    return fileSaver.saveAs(blob, fileName);
  });
}
export async function downloadAndZip (files, name) {
  return Promise.all(files.map(({url})=>{
    return fetch(url).then(async (res) => {
      const decodeUrl = decodeURIComponent(url);
      const blob = await res.blob();
      let fileName = decodeUrl.substring(decodeUrl.lastIndexOf('/') + 1, url.length);
      if (fileName.indexOf('.') < 0) {
        const fileType = await fromBlob(blob);
        fileName = `${fileName}.${fileType.ext}`;
      }
      return {
        name: fileName,
        blob,
      };
    });
  })).then(files=>{
    return zipFiles(files, name);
  });
}
