<template>
  <div class="h-100">
    <query-form
      class="content_block"
      label-width="120px"
      :keepOpenedRow="typeFilterVisible?3:1"
      @search="handleSearch"
      @reset="handleReset"
    >
      <el-form-item v-if="typeFilterVisible" :label="`${curPageConfig.abbreviation}类型`" :span="24">
        <check-tag v-model="queryType" @change="handleSearchModel">
          <check-tag-item :value="undefined" label="全部"></check-tag-item>
          <check-tag-item
            v-for="i in OrderTypeList.filter(i => !!i.useFlag)"
            :label="i.name"
            :value="i.code"
            :key="i.code"
          ></check-tag-item>
        </check-tag>
      </el-form-item>
      <el-form-item v-for="item in visibleQueryConditions" :key="item.id" :label="item.columnName" :props="item.columnKey">
        <Render :formModel="queryForm" :conf="normalLizeConfig(item.columnValue)"></Render>
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="orderList"
      ref="orderTable"
      class="content_block"
      :checkbox-config="checkboxConfig"
      :radio-config="radioConfig"
      :id="configCode"
      show-right-toolbar
      columns-custom
      :columnList="columnList"
      :pagination.sync="pagination"
      @current-change="getDataList"
      @sort-change="getDataList"
      :leftToolbar="tableButtons"
      @selection-change="handleSelectionChange"
    />
  </div>
</template>
<script>
import { commonFormConfigJson } from './urlConfig.js';
import _ from 'lodash';
import { getDefaultValueByTag, getDisplayValue } from '@/components/form-generator/utils/component.js';
import {
  getCustomQueryConditions,
  getCustomQueryColumn,
  getFormTypeListByConfigId,
  getCommonQueryPageList,
  deleteCommonQueryPage,
  exportCommonQueryPage,
  customSettingFormBtnHandle,
} from '@/api/commonForm';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import Render from '@/components/form-generator/render/render.js';
import downloadFile, { downloadAndZip } from '@/utils/download';
import previewImage from '@/components/imagePreviewer/previewImage';
import DeviceApi from '@/api/device';
import dayjs from 'dayjs';
export default {
  mixins: [TablePaginationMixin],
  components: {
    Render,
  },
  props: {
    configCode: {
      required: true,
    },
    renderMode: {
      // 展示模式
      // NORMAL 正常，可进行增删改查
      // MULTIPLESELECT 多选数据选择模式，仅可进行多选数据筛选
      // SINGLESELECT 单选数据选择模式，仅可进行单选数据筛选
      type: String,
      default: 'NORMAL',
      validator: function (value) {
        // 这个值必须匹配下列字符串中的一个
        return ['NORMAL', 'MULTIPLESELECT', 'SINGLESELECT'].includes(value);
      },
    },
    ignoreQueryFilterKeys: {
      // 查询条件屏蔽
      type: Array,
      default: ()=>[],
    },
    typeFilterVisible: {
      // 类型筛选是否可见
      type: Boolean,
      default: true,
    },
    defaultQueryType: {
      // 默认选中类型
      type: String,
    },
    defaultQueryForm: {
      // 默认筛选条件值
      type: Object,
    },
    defaultSelectedIds: {
      // 当renderMode为选择模式时，可设置默认选中项
      type: Array,
      default: ()=>[],
    },
  },
  data () {
    return {
      queryType: this.defaultQueryType,
      OrderTypeList: [],
      queryConditions: [],
      queryForm: {},
      orderList: [],
      columnList: [],
      idList: _.cloneDeep(this.defaultSelectedIds),
      registerRuleForm: {},
    };
  },
  computed: {
    checkboxConfig () {
      return {
        reserve: true,
        checkRowKeys: this.idList,
      };
    },
    radioConfig () {
      return {
        reserve: true,
        checkRowKey: this.idList && this.idList[0] ? this.idList[0] : null,
      };
    },
    visibleQueryConditions () {
      return this.queryConditions.filter(t=>this.ignoreQueryFilterKeys.indexOf(t.columnKey) < 0);
    },
    curAuth () {
      return commonFormConfigJson[this.configCode].authList;
    },
    customColumns () {
      return commonFormConfigJson[this.configCode].columns || {};
    },
    curPageConfig () {
      return commonFormConfigJson[this.configCode];
    },
    routeTab () {
      if (this.renderMode === 'NORMAL') {
        return this.curPageConfig.abbreviation !== '资产台账' ? `${this.curPageConfig.abbreviation}列表` : this.curPageConfig.abbreviation;
      } else {
        return undefined;
      }
    },
    tableButtons () {
      if (this.renderMode !== 'NORMAL') {
        return [];
      }
      let actions = [
        {
          type: 'custom',
          render: () => {
            const modelLis = this.OrderTypeList.filter(i => !!i.status);
            return modelLis.length ? (
              <el-dropdown v-auth={this.curAuth.add}>
                <el-button type="primary">
                  新增<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown" style={{ 'max-height': '260px', 'overflow-y': 'auto' }}>
                  {modelLis.map(i => (
                    <el-dropdown-item
                      nativeOnClick={this.handleActionClick.bind(this, { templateCode: i.code }, 'add')}
                    >
                      {i.name}
                    </el-dropdown-item>
                  ))}
                </el-dropdown-menu>
              </el-dropdown>
            ) : (
              ''
            );
          },
        },
      ];
      if (this.configCode === 'DEVICE_FILE') {
        actions.push({
          type: 'custom',
          render: () => {
            return (
              <el-button v-auth={'device:add'} onClick={this.handleDownload.bind(this)}>
                批量下载二维码
              </el-button>
            );
          },
        });
        actions.push({
          name: '批量新增',
          size: 'small',
          click: () => {
            this.$router.push({
              name: 'ORDER_IMPORT',
              params: {
                type: this.configCode,
              },
            });
          },
          permission: ['device:add', 'workOrder:add'],
        });
        actions.push({
          name: '批量导出',
          size: 'small',
          status: 'primary',
          plain: true,
          click: () => {
            const columnkeyList = this.$refs.orderTable
              .getColumns()
              .filter(t => t.property)
              .map(t => t.property);
            exportCommonQueryPage({
              systemConfigCodeFlag: this.configCode,
              customizeSystemConfigCode: this.configCode,
              customizeBusinessTypeCode: this.queryType,
              ...this.queryForm,
              columnkeyList,
            }).then(({ data }) => {
              const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
              const fileTitle = `设备_${dayjs(Date.now()).format('YYYYMMDDHHmmss')}`;
              downloadFile({
                data,
                type,
                fileTitle,
              });
            });
          },
          permission: ['device:export', 'workOrder:export'],
        });
      }

      return actions;
    },
  },
  mounted () {
    if (this.renderMode === 'NORMAL') {
      document.title = this.routeTab;
      this.getFormCategoryList();
    }
    this.handleSearchModel();
  },
  methods: {
    handleDownload () {
      if (!this.idList.length) return this.$message.warning('请选择下载项');
      let fileName = '';
      DeviceApi.downloadQrCode({ idList: this.idList }).then(({ data }) => {
        try {
          const blob = new Blob([data], {
            type: 'application/pdf',
          });

          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(blob, fileName);
          } else {
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.setAttribute('download', fileName);

            if (typeof link.download === 'undefined') {
              link.setAttribute('target', '_blank');
            }

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(new Blob([data]));
          }
        } catch (e) {
          console.log(e);
        }
      });
    },
    getFormCategoryList () {
      getFormTypeListByConfigId({
        systemConfigId: this.curPageConfig.systemConfigId,
        customizeSystemConfigCode: this.configCode,
      }).then(rs => {
        this.OrderTypeList = rs.body?.map(i => ({
          ...i,
          templateId: i.id,
        }));
      });
    },
    async getCustomQueryConditionsHandle () {
      this.queryForm = {};
      await getCustomQueryConditions({
        customizeSystemConfigCode: this.configCode,
        customizeBusinessTypeCode: this.queryType,
      }).then(({ body }) => {
        this.queryConditions = _.cloneDeep(body) || [];
        this.queryConditions.forEach(column => {
          column.tag = column.columnType;
          this.$set(this.queryForm, column.columnKey, getDefaultValueByTag(column.columnValue));
        });
        this.defaultQueryForm && Object.keys(this.defaultQueryForm).forEach(key=>{
          if (this.defaultQueryForm[key] !== null && this.defaultQueryForm[key] !== undefined) {
            this.$set(this.queryForm, key, this.defaultQueryForm[key]);
          }
        });
      });
    },
    handleReset () {
      this.queryConditions.forEach(column => {
        column.tag = column.columnType;
        this.$set(this.queryForm, column.columnKey, getDefaultValueByTag(column.columnValue));
      });
      this.defaultQueryForm && Object.keys(this.defaultQueryForm).forEach(key=>{
        if (this.defaultQueryForm[key] !== null && this.defaultQueryForm[key] !== undefined) {
          this.$set(this.queryForm, key, this.defaultQueryForm[key]);
        }
      });
      this.handleSearch();
    },
    async handleSearchModel () {
      await this.getCustomQueryConditionsHandle();
      this.handleSearch();
    },
    normalLizeConfig (schema) {
      schema.disabled = false;
      const config = schema.__config__;
      if (config.hasOwnProperty('filterParam')) {
        config.filterParam = null;
      }
      if (config?.tag === 'span') {
        schema.__config__.tag = 'el-input';
      } else if (config?.tag === 'el-date-picker' || config?.tag === 'el-time-picker') {
        schema = {
          __config__: config,
          style: {
            width: '100%',
          },
          type: 'daterange',
          defaultTime: ['00:00:00', '23:59:59'],
          valueFormat: 'timestamp',
          'range-separator': '至',
          'start-placeholder': '开始日期',
          'end-placeholder': '结束日期',
        };
      }
      schema.placeholder
        = schema.placeholder || `${schema.__config__.tag === 'el-input' ? '请输入' : '请选择'}${schema.__config__.label}`;
      return {
        ...schema,
        on: {
          input: val => {
            this.$set(this.queryForm, config.renderKey, val);
          },
        },
      };
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getDataList();
    },
    getDataList () {
      getCustomQueryColumn({
        customizeSystemConfigCode: this.configCode,
        customizeBusinessTypeCode: this.queryType,
      }).then(({ body }) => {
        this.columnList = [];
        if (this.renderMode === 'NORMAL') {
          if (this.curPageConfig.hasCheckbox) {
            this.columnList.push({
              minWidth: '60px',
              type: 'checkbox',
              label: '全选',
              fixed: 'left',
            });
          }
        } else if (this.renderMode === 'SINGLESELECT') {
          this.columnList.push({
            minWidth: '60px',
            type: 'radio',
            label: '全选',
            fixed: 'left',
          });
        } else if (this.renderMode === 'MULTIPLESELECT') {
          this.columnList.push({
            minWidth: '60px',
            type: 'checkbox',
            label: '全选',
            fixed: 'left',
          });
        }

        body?.forEach(colm => {
          this.columnList.push({
            tooltip: true,
            label: colm.columnName,
            minWidth: '160px',
            prop: colm.columnKey,
            fixed: colm.columnKey === 'code' ? 'left' : '',
            render: (h, { row }) => {
              if (colm.columnKey === 'code') {
                if (this.renderMode === 'NORMAL') {
                  return (
                    <span class="text-primary cursor-pointer" onClick={this.handleActionClick.bind(this, row, 'detail')}>
                      {row.code}
                    </span>
                  );
                } else {
                  return (
                    <span>
                      {row.code}
                    </span>
                  );
                }
              } else if (colm.columnType === 'upload') {
                // 特殊处理文件预览
                const fileList = (row[colm.columnKey] || []).map(t => ({ url: t.url, type: t.type }));
                if (!fileList[0]) {
                  return '--';
                }
                return (
                  <div>
                    <el-link type="primary" onClick={() => previewImage({ fileList })}>
                      预览
                    </el-link>
                    <el-divider direction="vertical"></el-divider>
                    <el-link
                      type="primary"
                      onClick={() => downloadAndZip(fileList, row.code).then(() => this.$message.success('下载成功'))}
                    >
                      下载
                    </el-link>
                  </div>
                );
              } else if (this.customColumns[colm.columnKey]) {
                return this.customColumns[colm.columnKey].call(this, h, row, this.handleSearch);
              } else {
                const columnKeyInfo = row.columnKeyInfo || {};
                const columnSchema = {
                  ...columnKeyInfo[colm.columnKey],
                  __config__: {
                    tag: colm.columnType,
                    renderKey: colm.columnKey,
                  },
                };
                return getDisplayValue(columnSchema, row);
              }
            },
          });
        });
        if (this.renderMode === 'NORMAL') {
          this.columnList.push({
            prop: 'action',
            exportable: false,
            label: '操作',
            minWidth: '200px',
            fixed: 'right',
            render: (h, { row }) => {
              let actions = [];

              if (row.buttonMsg) {
                if (row.buttonMsg.length > 3) {
                  // 操作列表过长处理
                  for (let index = 0; index < 2; index++) {
                    const btn = row.buttonMsg[index];
                    actions.push(
                      <span class="cursor-pointer text-primary mr-2"
                        style={{wordBreak: 'keep-all'}}
                        onClick={this.handleActionClickNew.bind(this, row, btn)}
                      >
                        {btn.buttonStyleMsg && btn.buttonStyleMsg.text ? btn.buttonStyleMsg.text : btn.name}
                      </span>,
                    );
                  }
                  actions.push(<el-dropdown onCommand={cmd=> this.handleActionClickNew(row, cmd)}>
                    <el-button type="text">更多<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown" >
                      {
                        row.buttonMsg.slice(2, row.buttonMsg.length).map(btn => {
                          return <el-dropdown-item key={btn.id} command={btn} >{btn.buttonStyleMsg && btn.buttonStyleMsg.text ? btn.buttonStyleMsg.text : btn.name}</el-dropdown-item>;
                        })
                      }
                    </el-dropdown-menu>
                  </el-dropdown>);
                } else {
                  row.buttonMsg && row.buttonMsg.forEach(btn => {
                    actions.push(
                      <span class="cursor-pointer text-primary mr-2"
                        style={{wordBreak: 'keep-all'}}
                        onClick={this.handleActionClickNew.bind(this, row, btn)}
                      >
                        {btn.buttonStyleMsg && btn.buttonStyleMsg.text ? btn.buttonStyleMsg.text : btn.name}
                      </span>,
                    );
                  });
                }
              }

              return <div>{...actions}</div>;
            },
          });
        }

        getCommonQueryPageList({
          pageSize: this.pagination.pageSize,
          pageNum: this.pagination.currentPage,
          systemConfigCodeFlag: this.configCode,
          type: this.queryType,
          ...this.queryForm,
        }).then(res => {
          this.orderList = res.body?.list || [];
          this.pagination.total = +res.body?.total || 0;
        });
      });
    },
    handleActionClickNew (row, action) {
      if (action.validateRequiredFlag) {
        const { id, templateCode } = row;
        const path = `/customForm/detail/${this.configCode}`;
        this.$router.push({
          path,
          query: { id, templateCode },
        });
      } else {
        const {pcUrl, httpMethod, name, buttonStyleMsg, validateRequiredFlag} = action;
        this.$confirm(`确定${buttonStyleMsg?.text || name || '操作'}吗?`, '提示').then(() => {
          const param = {
            ...row,
            validateRequiredFlag,
          };
          return customSettingFormBtnHandle({
            url: pcUrl,
            method: httpMethod,
            param,
          }).then(()=>{
            this.$message.success(`${buttonStyleMsg?.text || name || '操作'}成功`);
            this.getDataList();
          });
        });
      }
    },
    handleActionClick (row, action) {
      switch (action) {
      case 'delete':
        this.$confirm(`确定删除 ${row.code} 吗?`, '删除').then(() => {
          deleteCommonQueryPage({
            id: row.id,
            systemConfigCodeFlag: this.configCode,
          }).then(() => {
            this.$message.success('删除成功');
            this.handleSearch();
          });
        });
        break;
      default:
        const { id, templateCode } = row;
        const path = `/customForm/detail/${this.configCode}`;
        this.$router.push({
          path,
          query: { id, templateCode },
        });
        break;
      }
    },
    handleSelectionChange (selectedLists) {
      this.idList = selectedLists.map(v => v.id);
      this.$emit('selectionChanged', selectedLists);
    },
  },
};
</script>
<style lang="scss">
.register_box {
  padding: 0 15px;
  .registerText {
    padding: 10px;
  }
}
.synergy_box {
  padding: 10px;
  .top_box {
    display: flex;
    padding: 15px 0;
    .top_label {
      flex: 1;
      font-size: 16px;
    }
    .top_value {
      flex: 1;
    }
  }
}

.custom_box {
  .custom_box_body {
    color: #999;
    padding: 15px;
  }
  .btn_box {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
