<script>
import CommonEnterprise from '@/components/commonEnterprise';
import mixin from './mixin';
export default {
  // 公共企业库 系统组件
  name: 'cCommonEnterprise',
  mixins: [mixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: '请选择企业',
    },
  },
  components: {
    CommonEnterprise,
  },
  data () {
    return {
      enterpriseId: this.value?.KeyNo,
      enterpriseName: this.value?.Name,
    };
  },
  watch: {
    value () {
      this.enterpriseId = this.value?.KeyNo;
      this.enterpriseName = this.value?.Name;
    },
  },
  methods: {
    handleUserChanged (data) {
      this.enterpriseId = data?.KeyNo;
      this.enterpriseName = data?.Name;
      this.$emit('input', {
        ...data,
        id: data.KeyNo,
        name: data?.Name,
      });
    },
  },
  render () {
    return (
      <CommonEnterprise
        size={this.inputSize}
        placeholder={this.placeholder}
        value={this.enterpriseId}
        name={this.enterpriseName}
        readonly={this.inputDisabled}
        onChange={this.handleUserChanged}
      ></CommonEnterprise>
    );
  },
};
</script>
