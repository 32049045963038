<script>
import mixin from './mixin';
import axios from 'axios';
import UserApi from '@/api/user';

import VirtualSelector from '@/components/common/virtualSelector';
export default {
  // 人员选择 系统组件
  name: 'CUserSelector',
  mixins: [mixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: '请选择人员',
    },
  },
  components: {
    VirtualSelector,
  },
  data () {
    return {
      userId: this.value?.id,
      userName: this.value?.name,
      loading: false,
      pageIndex: 1,
      isReachLast: false,
      optionList: [],
    };
  },
  watch: {
    value () {
      this.userId = this.value?.id;
      this.userName = this.value?.name;
    },
  },
  methods: {
    handleChange (item) {
      this.$emit('input', item || {});
    },
    handleSearch (name) {
      this.pageIndex = 1;
      this.isReachLast = false;
      this.optionList.splice(0, this.optionList.length);
      this.getDataList(name);
    },
    loadMore (keyword) {
      if (!this.isReachLast) {
        this.pageIndex += 1;
        this.getDataList(keyword);
      }
    },
    getDataList (name) {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      const cancelToken = axios.CancelToken;
      this.cancelTokenSource = cancelToken.source();
      this.loading = true;
      UserApi.getUserList({
        name,
        findDepartmentFlag: true,
        pageNum: this.pageIndex,
        pageSize: 10,
        accountStatus: 'ACTIVE',
        status: 'ON_JOB',
      }, {
        stopDefaultLoading: true,
        cancelToken: this.cancelTokenSource.token,
      })
        .then(res => {
          this.loading = false;
          this.isReachLast = (res.body?.list?.length || 0) < 10;
          this.optionList = this.optionList.concat(res?.body?.list || []);
        })
        .catch((res) => {
          if (res?.heads?.code !== -100) {
            this.loading = false;
          }
        });
    },
  },
  render () {
    return (
      <VirtualSelector
        isLoading={this.loading}
        placeholder={this.placeholder}
        v-model={this.userId}
        optionList={this.optionList}
        name={this.userName}
        on={{
          'update:name': (val)=>{ this.userName = val; },
        }}
        readonly={this.inputDisabled}
        filterable={true}
        remote={true}
        option={{
          label: 'name',
          key: 'id',
          desc: 'departmentName',
        }}
        size={this.inputSize}
        remoteMethod={this.handleSearch}
        loadMore={this.loadMore}
        onChange={this.handleChange}>
      </VirtualSelector>
    );
  },
};
</script>
