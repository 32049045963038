import mixin from './mixin';
export default {
  // 定位，系统组件
  name: 'c-location',
  mixins: [mixin],
  props: {
    value: Object,
  },
  render () {
    return <span>{this.value?.address}</span>;
  },
};
