<template>
  <div class="point_box">
    <div class="point_ul" v-if="activities.length">
      <div
        class="point_li"
        v-for="(activity, index) in activities"
        :key="index"
        :class="{ active_line: index === activeIndex, unrequiredCheck: !activity.requiredFlag && !activity.checkFlag }"
        @click.stop="avtivingHandle(activity, index)"
      >
        <div class="top">
          <div class="timeline_item_wrapper">
            <div class="text_box">
              <div class="point_num">{{ index + 1 }}</div>
              <div class="point_name">{{ activity.nodeName }}</div>
            </div>
            <div v-if="!activity.requiredFlag" class="checkoBox">
              <el-switch
                :disabled="index === activeIndex"
                @click.native.stop
                v-model="activity.checkFlag"
                active-color="#0F89FF"
                inactive-color="rgba(36, 40, 50, 0.2)"
              ></el-switch>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="timeline_item__tail"></div>
          <div class="timeline_item__timestamp">{{ activity.rearStatusStr }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activities: {
      require: true,
      type: Array,
    },
    activePointIndex: {
      type: Number,
      required: true,
    },
    drawingList: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      activeIndex: this.activePointIndex,
    };
  },
  methods: {
    avtivingHandle (item, index) {
      if (!item.requiredFlag && !item.checkFlag) return;
      // 校验分组名称合法(不能重复)
      if (this.drawingList?.length) {
        let curRowLabels = this.drawingList.map(i => i.__config__.label);
        if (curRowLabels.length !== [...new Set(curRowLabels)].length) {
          return this.$message.error('分组名称不能重复');
        }
        for (let i = 0; i < this.drawingList.length; i++) {
          let curColLabels = this.drawingList[i].__config__.children
            .filter(t => t.__config__.tag !== 'span')
            .map(i => i.__config__.label);
          if (curColLabels.length !== [...new Set(curColLabels)].length) {
            return this.$message.error('同一分组内控件名称不能重复');
          }
        }
      }
      if (this.activeIndex !== index) {
        this.activeIndex = index;
        this.$emit('active-id', item.status, index);
      }
    },
  },
  watch: {
    activePointIndex () {
      this.activeIndex = this.activePointIndex;
    },
  },
};
</script>
<style lang="scss" scoped>
$activePoint: #0f89ff;
$activePointBorder: #9ec9f2;
$unactivePoint: #90949B;
$unactivePointBg: #dadce1;
.point_box {
  // min-width: 143px;
  padding-top: 20px;
  padding-right: 18px;
  .point_ul {
    .point_li {
      // display: flex;
      .top {
        cursor: pointer;
        margin-bottom: 8px;
        .timeline_item_wrapper {
          display: flex;
          .text_box {
            display: flex;
            width: 88px;
            height: 34px;
            background: #ffffff;
            border: 3px solid transparent;
            border-radius: 17px;
            padding: 4px 0 4px 6px;
            box-sizing: border-box;
            color: rgba(36, 40, 50, 0.6);
            flex-shrink: 0;
            .point_num {
              width: 18px;
              height: 18px;
              line-height: 18px;
              text-align: center;
              border-radius: 50%;
              border: 1px solid rgba(36, 40, 50, 0.6);
              font-size: 12px;
            }
            .point_name {
              margin-left: 11px;
              font-size: 16px;
              line-height: 22px;
            }
            &:hover {
              border-color: $activePointBorder;
            }
          }
          .checkoBox {
            line-height: 30px;
            flex-shrink: 0;
            margin-left: 6px;
            width: 40px;
            height: 20px;
            border-radius: 10px;
          }
        }
      }
      .bottom {
        height: 24px;
        display: flex;
        margin-bottom: 8px;
        .timeline_item__tail {
          width: 1px;
          height: 24px;
          background: $activePoint;
          border-radius: 1px;
          margin-left: 19px;
        }
        .timeline_item__timestamp {
          flex: 1;
          font-size: 12px;
          font-weight: 400;
          color: rgba(36, 40, 50, 0.3);
          line-height: 12px;
          font-weight: 400;
          line-height: 24px;
          margin-left: 14px;
        }
      }
      &:last-child > .bottom {
        display: none;
      }
      &.unrequiredCheck {
        font-weight: 400;
        .top {
          cursor: default;
          .timeline_item_wrapper {
            .text_box {
              background: #dadce1;
              .point_num {
                background: $unactivePoint;
                border: 1px solid $unactivePoint;
                color: #ffffff;
              }
              .point_name {
                color: rgba(36, 40, 50, 0.6);
              }
            }
          }
        }
        .bottom {
          .timeline_item__tail {
            background: rgba(15, 137, 255, 0.3);
          }
        }
      }
      &:hover,
      &.unrequiredCheck:hover {
        .top .timeline_item_wrapper {
          .text_box {
            background: #ffffff;
            border-color: $activePointBorder;
            color: rgba(15, 137, 255, 0.8);
            .point_num {
              background: $activePoint;
              border: 1px solid $activePoint;
              color: #ffffff;
            }
            .point_name {
              color: rgba(15, 137, 255, 0.8);
            }
          }
        }
      }
      &.active_line {
        .top .timeline_item_wrapper {
          .text_box {
            border-color: $activePointBorder;
            color: rgba(15, 137, 255, 0.8);
            .point_num {
              background: $activePoint;
              border: 1px solid $activePoint;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
</style>
