<template>
  <div class="right-board">
    <el-tabs v-model="currentTab" class="center-tabs">
      <el-tab-pane label="节点属性" name="step" />
      <el-tab-pane label="组件属性" v-if="activeData" name="field" />
      <el-tab-pane label="表单属性" v-if="formConf" name="form" />
    </el-tabs>
    <div class="field-box">
      <div class="right-scrollbar">
        <!-- 节点属性 -->
        <el-form v-show="currentTab === 'step'" size="mini" label-width="100px" class="step-info">
          <el-form-item :label="process ? `${process.nodeName}：` : ''">
            {{ process ? process.nodeDesc : '暂无' }}
          </el-form-item>
          <el-form-item
            v-if="process && process.customizeButtonResDTOList.length"
            :label="process ? `${process.nodeName}功能：` : '节点功能'"
          >
            <el-checkbox
              v-for="(checkItem, index) in process.customizeButtonResDTOList"
              :key="index"
              v-model="checkItem.checkFlag"
              :disabled="checkItem.requiredFlag"
            >
              {{ checkItem.name }}
            </el-checkbox>
          </el-form-item>
          <el-form-item v-if="processIndex > 0" label-width="0" class="point_class">
            <div class="area_label">控件显示范围</div>
            <el-radio-group v-model="allFieldsVisible" @change="handleToggleReversedField">
              <el-radio-button :label="1">全部节点</el-radio-button>
              <el-radio-button :label="0">仅当前节点</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <!-- 组件属性 -->
        <el-form
          v-if="activeData"
          v-show="currentTab === 'field'"
          size="mini"
          label-width="90px"
          :disabled="
            isAdmin
              ? false
              : activeData.__config__.isReservedField || activeData.__config__.stepSourceId !== process.status
          "
        >
          <el-form-item label="组件类型">
            {{ getComponentType(activeData) }}
          </el-form-item>
          <el-form-item label="字段名">
            <el-input v-model="activeData.__config__.renderKey" :disabled="false" placeholder="请输入字段名" />
          </el-form-item>
          <el-form-item label="系统字段" v-if="isAdmin">
            <el-switch
              :value="activeData.__config__.isReservedField"
              :disabled="false"
              @change="
                val => {
                  this.$set(this.activeData.__config__, 'isReservedField', val);
                }
              "
              placeholder="请输入字段名"
            />
          </el-form-item>
          <component
            :is="activeData.__config__.settingTemplate || activeData.__config__.tag + '-config'"
            :activeData="activeData"
          />
        </el-form>
        <!-- 表单属性 -->
        <el-form v-if="formConf" v-show="currentTab === 'form'" size="mini" label-width="90px">
          <el-form-item label="标签对齐">
            <el-radio-group v-model="formConf.labelPosition">
              <el-radio-button label="left">左对齐</el-radio-button>
              <el-radio-button label="right">右对齐</el-radio-button>
              <el-radio-button label="top">顶部对齐</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标签宽度">
            <el-input v-model.number="formConf.labelWidth" type="number" placeholder="请输入标签宽度" />
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import * as ComponentConfig from '@/components/form-generator/config';
import { getComponentType } from '../../util';
export default {
  components: {
    ...ComponentConfig,
  },
  props: ['activeData', 'formConf', 'process', 'processIndex', 'isAdmin'],
  data () {
    return {
      currentTab: 'step',
      currentNode: null,
      currentIconModel: null,
      allFieldsVisible: 0,
      getComponentType,
      userInfo: {},
    };
  },
  watch: {
    process () {
      this.currentTab = 'step';
      this.allFieldsVisible = 0;
    },
    activeData (val) {
      if (val) {
        this.currentTab = 'field';
      } else {
        this.currentTab = 'step';
      }
    },
  },
  methods: {
    handleToggleReversedField () {
      this.$emit('toggle-item-visible', this.allFieldsVisible);
    },
  },
};
</script>

<style lang="scss" scoped>
.right-board {
  width: 350px;
  margin-left: 10px;
  background: #fff;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 0 8px 0 12px;
  box-sizing: border-box;
  .field-box {
    flex: 1;
    overflow: hidden;
  }
  .right-scrollbar {
    height: 100%;
    padding-right: 16px;
    padding-bottom: 8px;
    box-sizing: border-box;
    overflow-x: hidden;

    .step-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      & > .el-form {
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
      }
      /deep/ .point_class{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        .area_label {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }

      & > div:last-child /deep/.el-form-item__content {
        width: 100%;
        .el-radio-group {
          display: flex;
          flex-direction: row;
          align-items: center;
          & > * {
            flex: 1;
            .el-radio-button__inner {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.select-item {
  display: flex;
  border: 1px dashed #fff;
  box-sizing: border-box;
  & .close-btn {
    cursor: pointer;
    color: #f56c6c;
  }
  & .el-input + .el-input {
    margin-left: 4px;
  }
}
.select-item + .select-item {
  margin-top: 4px;
}
.select-item.sortable-chosen {
  border: 1px dashed #409eff;
}
.select-line-icon {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #777;
}
.option-drag {
  cursor: move;
}
.time-range {
  .el-date-editor {
    width: 227px;
  }
  ::v-deep .el-icon-time {
    display: none;
  }
}
.node-label {
  font-size: 14px;
}
.node-icon {
  color: #bebfc3;
}

/deep/.reg-item {
  padding: 12px 6px;
  background: #f8f8f8;
  position: relative;
  border-radius: 4px;
  .close-btn {
    position: absolute;
    right: -6px;
    top: -6px;
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    color: #fff;
    text-align: center;
    z-index: 1;
    cursor: pointer;
    font-size: 12px;
    &:hover {
      background: rgba(210, 23, 23, 0.5);
    }
  }
  & + .reg-item {
    margin-top: 18px;
  }
}
</style>
