
export default {
  props: {
    activeData: Object,
  },
  render () {
    return (
      <div>
        <el-form-item label="分组名称">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入分组名称" />
        </el-form-item>
      </div>
    );
  },
};
