import common from '../element/mixins';
import { getFormTypeListByConfigId } from '@/api/commonForm';
export default {
  mixins: [common],
  data () {
    return {
      categoryList: [],
    };
  },
  created () {
    getFormTypeListByConfigId({
      systemConfigId: '945697336431206470',
      customizeSystemConfigCode: 'BASE_FILE',
      status: true,
    }).then(({ body }) => {
      this.categoryList = body;
    });
  },
  props: {
    activeData: Object,
  },
  render () {
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="显示标题">
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="占位提示">
          <el-input
            value={this.activeData.placeholder}
            placeholder="请输入占位提示"
            onInput={val => {
              this.$set(this.activeData, 'placeholder', val);
            }}
          />
        </el-form-item>
        <el-form-item label="表单栅格">
          <el-radio-group class="fixed" v-model={this.activeData.__config__.span}>
            <el-radio-button label={6}></el-radio-button>
            <el-radio-button label={8}></el-radio-button>
            <el-radio-button label={12}></el-radio-button>
            <el-radio-button label={24}></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
        <el-form-item label={this.activeData.__config__.label}>
          <el-select vModel={this.activeData.archiveType} clearable>
            {this.categoryList.length
              && this.categoryList.map((item, index) => (
                <el-option key={index} value={item.code} label={item.name}></el-option>
              ))}
          </el-select>
        </el-form-item>
      </div>
    );
  },
};
