import Vue from 'vue';
import ImagePreviewer from './ImagePreviewer';
let instance;
const initInstance = ()=>{
  instance = new Vue({
    components: {ImagePreviewer},
    props: {
      fileList: Array,
      initialIndex: Number,
    },
    data () {
      return {
        visible: false,
      };
    },
    methods: {
      handleClose () {
        this.visible = false;
      },
      open (fileList, activeIndex) {
        this.visible = true;
        this.fileList = fileList;
        this.initialIndex = activeIndex;
      },
    },
    // eslint-disable-next-line no-unused-vars
    render: function (h) {
      return <ImagePreviewer ref="imagePreviewer" fileList={this.fileList}
        visible={this.visible}
        initialIndex={this.initialIndex}
        onClose={this.handleClose}/>;
    },
  });
  let previewContainer = document.createElement('div');
  previewContainer.setAttribute('id', 'preview_container');
  const container = document.createElement('div');
  previewContainer.append(container);
  document.body.append(previewContainer);
  return instance.$mount(container);
};
const previewImage = ({fileList, initialIndex})=>{
  if (!instance) {
    initInstance();
  }
  instance.open(fileList, initialIndex);
};
export default previewImage;
