<template>
  <div ref="address_container"
    class="c_province_address"
    :style="containerStyle">
    <div  class="area_address">
      <areaCascader
        v-if="resetArea"
        :addresstype="addresstypeEnd"
        :readonly="inputDisabled"
        :provinceId.sync="snapForm.provinceId"
        :provinceName.sync="snapForm.provinceName"
        :cityId.sync="snapForm.cityId"
        :cityName.sync="snapForm.cityName"
        :areaId.sync="snapForm.areaId"
        :areaName.sync="snapForm.areaName"
        @areaChange="areaChange"
      />
    </div>
    <div class="detail_address">
      <el-input
        v-if="addresstype === 4"
        :value="snapForm.detailAddress"
        :placeholder="addressPlaceholder"
        :disabled="inputDisabled"
        @input="setAddress"
        :includeCountry="false"
        :clearable="clearable"
      />
    </div>
  </div>
</template>
<script>
import mixin from './mixin';
import areaCascader from '@/components/common/areaCascader';
export default {
  name: 'c-province-address',
  mixins: [mixin],
  components: {
    areaCascader,
  },
  props: {
    value: {
      type: Object,
    },
    addresstype: {
      type: Number,
      default: 3,
    },
    addressPlaceholder: {
      type: String,
    },
    clearable: {
      type: Boolean,
    },
  },
  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },
  data () {
    return {
      snapForm: {
        provinceId: '',
        provinceName: '',
        cityId: '',
        cityName: '',
        areaId: '',
        areaName: '',
        detailAddress: '',
      },
      resetArea: true,
      addresstypeEnd: Math.min(this.addresstype, 3),
      containerWidth: 0,
    };
  },
  computed: {
    containerStyle () {
      if (this.containerWidth >= 400) {
        return {
          'display': 'flex',
          'flex-direction': 'row',
        };
      } else {
        return {
          'display': 'flex',
          'flex-direction': 'column',
        };
      }
    },
  },
  methods: {
    setAddress (val) {
      this.snapForm.detailAddress = val;
      this.$emit('input', { ...this.snapForm });
    },
    areaChange () {
      this.$emit('input', { ...this.snapForm });
    },
    calcWidth () {
      const { width } = this.$refs.address_container.getBoundingClientRect();
      this.containerWidth = width;
    },
  },
  watch: {
    addresstype: {
      immediate: true,
      handler () {
        this.resetArea = false;
        this.$nextTick(() => {
          this.resetArea = true;
        });
      },
    },
    value () {
      if (JSON.stringify(this.value) !== JSON.stringify(this.snapForm)) {
        this.snapForm = { ...this.value };
      }
    },
  },
  mounted () {
    this.calcWidth();
  },
  created () {
    this.snapForm = { ...this.value };
    window.addEventListener('resize', this.calcWidth);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calcWidth);
  },
};
</script>

<style lang="scss" scoped>
.c_province_address {
  width: 100%;

  .area_address {
    flex:1;
  }

  .detail_address {
    flex:1;
  }
}
</style>
