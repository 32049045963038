
export default {
  methods: {
    changeRenderKey () {

    },
    spanChange (val) {
      this.formConf.span = val;
    },
    handleAddRegex () {
      this.activeData.__config__.regList.push({
        pattern: '',
        message: '',
      });
    },
    handleRemoveRegex (index) {
      this.activeData.__config__.regList.splice(index, 1);
    },
  },
  props: {
    activeData: Object,
  },

  render () {
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="显示标题" >
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="占位提示" >
          <el-input v-model={this.activeData.placeholder} placeholder="请输入占位提示" />
        </el-form-item>
        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
      </div>
    );
  },
};
