import server from '../request';

// 自定义查询条件查询
export const getCustomQueryConditions = ({ customizeSystemConfigCode, customizeBusinessTypeCode }) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/conditional/queryList', {
    customizeSystemConfigCode,
    customizeBusinessTypeCode,
  });
};

// 自定义表头查询
export const getCustomQueryColumn = ({ customizeSystemConfigCode, customizeBusinessTypeCode }) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/column/queryList', {
    customizeSystemConfigCode,
    customizeBusinessTypeCode,
  });
};
// 查询模板列表
export const getFormTypeListByConfigId = data =>
  server.post('/xiaozi-xmb/wireless/saas/customize/businessType/queryAll', data);

// 查询数据列表
export const getCommonQueryPageList = (data, config) => {
  const orderBy = [
    {
      orderByProperty: data.orderByProperty || 'lastUpdateTime',
      orderByType: data.orderByType || 'DESC',
    },
  ];
  const param = { orderBy, ...data };
  return server.post('/xiaozi-xmb/wireless/saas/customize/common/queryPageList', param, config);
};

// 新增
export const addCommonQueryPage = data => server.post('/xiaozi-xmb/wireless/saas/customize/common/save', data);

// 修改
export const updateCommonQueryPage = data => server.post('/xiaozi-xmb/wireless/saas/customize/common/update', data);

// 详情
export const getCommonQueryPageDetail = data => server.post('/xiaozi-xmb/wireless/saas/customize/common/detail', data);

// 删除
export const deleteCommonQueryPage = data => server.post('/xiaozi-xmb/wireless/saas/customize/common/delete', data);

// 导出
export const exportCommonQueryPage = data => {
  const orderBy = [
    {
      orderByProperty: data.orderByProperty || 'lastUpdateTime',
      orderByType: data.orderByType || 'DESC',
    },
  ];
  const param = { orderBy, ...data };
  return server.post('/xiaozi-xmb/wireless/saas/customize/common/export', param, {
    responseType: 'blob',
  });
};

// 获取excel模板对应的布局
export const getSchemaWithExcelTemplate = data =>
  server.post('/xiaozi-xmb/wireless/saas/customize/common/downloadTemplate', data, {
    responseType: 'blob',
  });

// 批量上传excel
export const batchImportExcel = data =>
  server.post('/xiaozi-xmb/wireless/saas/customize/printTemplate/templateImport', data);
// 批量保存自定表单
export const batchSaveCommonForm = (data, config) => {
  return server.post('/xiaozi-xmb/wireless/saas/customize/printTemplate/bulkAdd', data, config);
};

// 盘点单 -盘点
export const updateInventory = data =>
  server.post('/xiaozi-xmb/wireless/saas/customize/inventory/updateInventory', data);

// 配置化 form btn
export const customSettingFormBtnHandle = ({ url, method, param }) => {
  let newUrl = url;
  let data = {};
  if (url.includes('?')) {
    newUrl = url.split('?')[0];
    const searchUrl = url.split('?')[1];
    let searchParam = searchUrl.split('&');
    if (searchParam?.length) {
      searchParam.forEach(i => {
        const paramArr = i.split('=');
        data[paramArr[0]] = paramArr[1];
      });
    }
  }
  data = { ...data, ...param };
  return server[method]?.(newUrl, data);
};

// 服务商管理  服务商支付生成订单
export const payServiceProvider = data => server.post('/xiaozi-xmb/wireless/saas/order/add', data);

// 服务商管理  启用-关闭 协同功能
export const coordinationService = data => server.post('/xiaozi-xmb/wireless/saas/serviceProvider/coordination', data);

// 服务商管理  邀请注册
export const invitatRegister = data => server.post('/xiaozi-xmb/wireless/saas/serviceProvider/invitation', data);

// 企业库
export const getEnterprisePage = (data, config) =>
  server.post('xiaozi-xmo/wireless/saas/employee/qcc/page', data, config);

// 移动端列表项配置
export const saveMobileList = data =>
  server.post('xiaozi-xmb/wireless/saas/customize/businessType/saveMobileList', data);
