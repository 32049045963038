import { getDefaultValueByTag, getDisplayValue } from '../utils/component.js';
import _ from 'lodash';
export default {
  name: 'parse_table_render',
  components: {
    Parser: () => import('../parser/Parser'),
  },
  data () {
    return {
      dialogVisible: false,
      dataStatus: 'NEW', // NEW:新增， EDIT:编辑
      currentData: {},
      currentDataIndex: 0,
      dataList: [],
    };
  },
  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },
  computed: {
    _elFormItemSize () {
      return (this.elFormItem || {}).elFormItemSize;
    },
    inputSize () {
      return this.scheme.__config__.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
    },
    inputDisabled () {
      return this.scheme.disabled || (this.elForm || {}).disabled;
    },
  },
  props: {
    scheme: Object,
    formModel: {
      type: Object,
      default: ()=>{
        return {};
      },
    },
    mode: {
      // 预览模式
      // 渲染模式
      type: String,
      default: 'render',
      validator: function (val) {
        if (!val) return true;
        return ['preview', 'render'].indexOf(val) >= 0;
      },
    },
  },
  watch: {
    dataList () {
      let modelVal = this.formModel[this.scheme.__config__.renderKey] || [];
      if (JSON.stringify(this.dataList) !== JSON.stringify(modelVal)) {
        this.formModel[this.scheme.__config__.renderKey] = _.cloneDeep(this.dataList);
      }
    },
  },
  methods: {
    handleFormSubmit () {
      this.$refs[this.scheme.__config__.renderKey].validForm().then(()=>{
        this.dialogVisible = false;
        if (this.dataStatus === 'NEW') {
          this.dataList.push(_.cloneDeep(this.currentData));
        } else {
          this.dataList.splice(this.currentDataIndex, 1, _.cloneDeep(this.currentData));
        }
        this.$refs.tableLayout?.refreshScroll();
      });
    },
    handleAction (row, index, action) {
      if (action === 'EDIT') {
        this.dataStatus = 'EDIT';
        this.dialogVisible = true;
        this.currentData = _.cloneDeep(row);
        this.currentDataIndex = index;
      } else if (action === 'DEL') {
        this.$confirm(`确认删除第${index + 1}条记录？`, '提示').then(()=>{
          this.dataList.splice(index, 1);
          this.$refs.tableLayout?.refreshScroll();
        });
      } else if (action === 'UP') {
        this.dataList.splice(index, 1);
        this.dataList.splice(index - 1, 0, row);
        this.$refs.tableLayout?.refreshScroll();
      } else if (action === 'DOWN') {
        this.dataList.splice(index, 1);
        this.dataList.splice(index + 1, 0, row);
        this.$refs.tableLayout?.refreshScroll();
      }
    },
    handleNew (fields) {
      let getViewModel = (nodeList)=> {
        nodeList && nodeList.forEach(node=>{
          if (node?.__config__?.layout === 'groupRowFormItem') {
            this.getViewModel(node?.__config__?.children);
          } else {
            this.$set(this.currentData, node.__config__.renderKey, getDefaultValueByTag(node));
          }
        });
      };
      getViewModel(fields);
      this.dialogVisible = true;
      this.dataStatus = 'NEW';
      this.$nextTick(()=>{
        this.$refs[this.scheme.__config__.renderKey].resetForm();
      });
    },
  },
  render () {
    const config = this.scheme.__config__;
    let columns = [{
      type: 'seq',
      title: '序号',
      width: '60px',
    }];
    let childForm = config.children[0] || {fields: []};
    childForm.fields.forEach(t=>{
      if (t.__config__.visible !== false) {
        columns.push({
          field: t.__config__.renderKey,
          title: t.__config__.label,
          minWidth: `${t.__config__.label.length * 16 + 20}px`,
          formatter: ({ row }) =>{
            return getDisplayValue(t, row);
          },
        });
      }
    });
    if (!this.inputDisabled) {
      columns.push({
        title: '操作',
        minWidth: '180px',
        fixed: 'right',
        slots: {
          default: ({row, rowIndex }) => {
            if (this.inputDisabled) {
              return [];
            }
            let actions = [];
            actions.push(<span class='cursor-pointer text-primary ml-1 ' onClick={this.handleAction.bind(this, row, rowIndex, 'EDIT')}>编辑</span>);
            if (rowIndex > 0) {
              actions.push(<span class='cursor-pointer text-primary ml-1 ' onClick={this.handleAction.bind(this, row, rowIndex, 'UP')}>上移</span>);
            }
            if (rowIndex < this.dataList.length - 1) {
              actions.push(<span class='cursor-pointer text-primary ml-1 ' onClick={this.handleAction.bind(this, row, rowIndex, 'DOWN')}>下移</span>);
            }
            actions.push(<span class='cursor-pointer text-primary ml-1 ' onClick={this.handleAction.bind(this, row, rowIndex, 'DEL')}>删除</span>);
            return [<div>{...actions}</div>];
          },
        },
      });
    }

    let toolbarConfig = this.inputDisabled ? null : {
      import: false,
      export: false,
      zoom: false,
      custom: false,
      slots: {
      buttons: ({ x }, h) => { // eslint-disable-line
          return [ h('el-button', {
            props: {
              type: 'primary',
              size: this.inputSize,
            },
            on: {
              click: ()=>{
                this.handleNew(childForm.fields);
              },
            },
          }, '新增')];
        },
      },
    };
    let footer = [];
    if (this.scheme.sumBy) {
      let sumItem = new Array(columns.length);
      sumItem[0] = '合计';
      this.scheme.sumBy.forEach(key=>{
        let index = columns.findIndex(t=>t.field === key);
        sumItem[index] = _.sum(this.dataList.map(t=>isNaN(t[key]) ? 0 : Number(t[key])));
        let columnSchema = childForm.fields.find(t=>t.__config__.renderKey === key);
        let precision = columnSchema?.precision;
        if (precision !== null && precision !== undefined) {
          sumItem[index] = sumItem[index].toFixed(precision);
        }
      });
      footer.push(sumItem);
    }
    let table = (<vxe-grid resizable={true}
      size={this.inputSize}
      data={this.dataList}
      ref="tableLayout"
      align="center"
      maxHeight="500px"
      height={this.mode === 'preview' ? 120 : null}
      show-footer={!!(this.scheme.sumBy && this.scheme.sumBy[0])}
      footer-method={()=>footer}
      toolbarConfig={toolbarConfig}
      autoResize={true}
      show-overflow={true}
      highlight-current-row={true}
      highlight-hover-row={true}
      highlight-current-column={true}
      highlight-hover-column={true}
      border="full"
      columns={columns}>
    </vxe-grid>);
    let dialog = <el-dialog title={this.inputDisabled ? '查看详情' : (this.dataStatus === 'NEW' ? '新增' : '编辑')}
      visible={true}
      custom-class="common_form_dialog_wrapper"
      close-on-click-modal={false}
      append-to-body={true}
      width="800px"
      onClose={()=>{ this.dialogVisible = false; }}>
      <Parser ref={this.scheme.__config__.renderKey}
        form-conf={childForm}
        formModel={this.currentData}/>
      <span slot="footer" >
        <el-button disabled={false} type="primary" plain onClick={()=>{ this.dialogVisible = false; }}>取 消</el-button>
        <el-button type="primary" onClick={this.handleFormSubmit}>确 定</el-button>
      </span>
    </el-dialog>;
    return <div>
      {table}
      {this.dialogVisible ? dialog : null}
    </div>;
  },
  created () {
    this.dataList = this.formModel[this.scheme.__config__.renderKey] || [];
  },
};
