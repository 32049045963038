import draggable from 'vuedraggable';
export default {
  components: {
    draggable,
  },
  methods: {
    handleAddRegex () {
      this.activeData.__config__.regList.push({
        pattern: '',
        message: '',
      });
    },
    handleRemoveRegex (index) {
      this.activeData.__config__.regList.splice(index, 1);
    },
    setOptionValue (item, val) {
      item.value = val;
    },
    handleDefaultValueTypeChange () {
      this.activeData.__config__.defaultValue = null;
      this.activeData.__config__.from = null;
    },
  },
  props: {
    disabled: Boolean,
  },
  inject: {
    elForm: {
      default: '',
    },
  },
  computed: {
    inputDisabled () {
      return this.disabled || (this.elForm || {}).disabled;
    },
  },
};
