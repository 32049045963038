<template>
  <virtual-selector
    :isLoading="loading"
    :placeholder="placeholder"
    v-model="defaultValue"
    :name.sync="defaultName"
    :optionList="optionList"
    :readonly="readonly"
    filterable
    remote
    :option="{
      label: 'name',
      key: 'id',
    }"
    :size="size"
    :remoteMethod="handleSearch"
    :load-more="loadMore"
    @change="handleChange">
  </virtual-selector>
</template>
<script>
import OrderApi from '@/api/order';
import VirtualSelector from '@/components/common/virtualSelector';
import axios from 'axios';
export default {
  name: 'SERVICE_PROVIDER',
  components: {
    VirtualSelector,
  },
  props: {
    value: {
      type: String,
    },
    size: {
      type: String,
      default: 'small',
    },
    name: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: String,
    },
    placeholder: {
      type: String,
      default () {
        return '请选择服务商';
      },
    },
  },
  data () {
    return {
      optionList: [],
      loading: false,
      defaultValue: this.value,
      defaultName: this.name,
      pageIndex: 1,
      isReachLast: false,
    };
  },
  watch: {
    value () {
      this.defaultValue = this.value;
    },
    name () {
      this.defaultName = this.name;
    },
  },
  methods: {
    getList (keyword) {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      const cancelToken = axios.CancelToken;
      this.cancelTokenSource = cancelToken.source();
      this.loading = true;
      OrderApi.getServiceProvider({
        name: keyword,
        pageNum: this.pageIndex,
        pageSize: 10,
        orderBy: [{
          orderByType: 'DESC',
          orderByProperty: 'lastUpdateTime',
        }],
        customerId: this.customerId,
      }, {
        stopDefaultLoading: true,
        cancelToken: this.cancelTokenSource.token,
      })
        .then(res => {
          this.optionList = this.optionList.concat(res.body.list || []);
          this.isReachLast = (res.body.list?.length || 0) < 10;
          this.loading = false;
        })
        .catch((res) => {
          if (res?.heads?.code !== -100) {
            this.loading = false;
          }
        });
    },
    handleChange (item) {
      this.$emit('input', item?.id);
      this.$emit('update:name', item?.name);
      this.$emit('change', item || {});
    },
    handleSearch (name) {
      this.pageIndex = 1;
      this.isReachLast = false;
      this.optionList.splice(0, this.optionList.length);
      this.getList(name);
    },
    loadMore (keyword) {
      if (!this.isReachLast) {
        this.pageIndex += 1;
        this.getList(keyword);
      }
    },
  },
};
</script>
