<template>
  <virtual-selector
    :isLoading="loading"
    :placeholder="placeholder"
    v-model="defaultValue"
    :name.sync="defaultName"
    :optionList="optionList"
    :readonly="inputDisabled"
    filterable
    :option="{
      label: 'name',
      key: 'id',
    }"
    :size="inputSize"
    @change="handleChange">
  </virtual-selector>
</template>
<script>
import mixin from './mixin';
import { getFormTypeListByConfigId } from '@/api/formSetting';
import VirtualSelector from '@/components/common/virtualSelector';

export default {
  name: 'C-TEMPLATE-SELECTOR',
  mixins: [mixin],
  components: {
    VirtualSelector,
  },
  props: {
    value: {
      type: Object,
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    relatedFormType: {
      type: String,
    },
  },
  watch: {
    value () {
      if (this.value?.id !== this.defaultValue) {
        this.defaultValue = this.value?.id;
        this.defaultName = this.value?.name;
      }
    },
  },
  data () {
    return {
      optionList: [],
      loading: false,
      defaultName: this.value?.name,
      defaultValue: this.value?.id,
    };
  },
  methods: {
    getList () {
      this.loading = true;
      this.relatedFormType && getFormTypeListByConfigId({
        customizeSystemConfigCode: this.relatedFormType,
      }).then(res => {
        let currentLists = res.body || [];
        this.optionList = currentLists.filter(t=>t.status);
        return currentLists;
      }).finally(() => {
        this.loading = false;
      });
    },
    handleChange (item) {
      this.$emit('input', item || {});
    },
  },
  created () {
    this.getList();
  },
};

</script>
<style lang="scss" >
.c-template-selector {
  max-height: 200px!important;
  overflow: auto;
}
</style>
