export default function (schema) {
  if (schema?.__config__?.tag === 'el-select') {
    return t=> t?.__config__?.tag === schema?.__config__?.tag && t['multiple'] === schema['multiple'];
  } else if (schema?.__config__?.tag === 'el-time-picker') {
    return t=> t?.__config__?.tag === schema?.__config__?.tag && t['is-range'] === schema['is-range'];
  } else if (schema?.__config__?.tag === 'el-date-picker') {
    return t=> t?.__config__?.tag === schema?.__config__?.tag && t['type'] === schema['type'];
  } else if (schema?.__config__?.tag === 'c-archive-collection') {
    return t=> t?.__config__?.tag === schema?.__config__?.tag && t['archiveType'] === schema['archiveType'] && t['configCode'] === schema['configCode'];
  } else if (schema?.__config__?.tag === 'c-template-selector') {
    return t=> t?.__config__?.tag === schema?.__config__?.tag && t['relatedFormType'] === schema['relatedFormType'];
  } else {
    return t=> t?.__config__?.tag === schema?.__config__?.tag;
  }
}
