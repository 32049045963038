import Enum from '@/utils/Enum';

const OrderSourceEnumList = [
  {
    value: 'ARCHIMEDES',
    name: '阿基米德',
  },
  {
    value: 'INTERIOR',
    name: '系统内部',
  },
];

export default new Enum(OrderSourceEnumList);
export { OrderSourceEnumList };
