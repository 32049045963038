import Vue from 'vue';

// vnode方式传入对话框子组件
const showModal = ({ title = '提示', content, data = {}, beforeClose, confirmButtonText, footer, width, ref }) => {
  let modalContainer = document.createElement('div');
  modalContainer.setAttribute('id', 'modal_container');
  document.body.append(modalContainer);
  return new Vue({
    data () {
      return {
        dialogVisible: true,
        ...data,
      };
    },
    methods: {
      handleConfirm () {
        if (beforeClose) {
          beforeClose.call(this, 'confirm', this, this.handleClose);
        } else {
          this.handleClose();
        }
      },
      handleClose () {
        this.dialogVisible = false;
      },
    },
    // eslint-disable-next-line no-unused-vars
    render: function (h) {
      const getContent = () => (typeof content === 'function' ? content.call(this, h, this) : <span>{content}</span>);
      const defaultFooter = () => (
        <div>
          <el-button type="primary" onClick={this.handleConfirm}>
            {confirmButtonText || '确 定'}
          </el-button>
          <el-button onClick={this.handleClose}>取 消</el-button>
        </div>
      );
      const ret = this.dialogVisible ? (
        <el-dialog
          title={title}
          visible={true}
          width={width}
          onBeforeClose={this.handleClose}
          onClose={this.handleClose}
          ref={ref}
        >
          {getContent()}
          <span slot="footer" class="dialog-footer">
            {footer ? footer.call(this, h, this) : defaultFooter()}
          </span>
        </el-dialog>
      ) : null;
      return ret;
    },
    beforeDestroy () {
      document.body.remove(modalContainer);
    },
  }).$mount(modalContainer);
};

// component方式传入对话框子组件
const showModal2 = ({ title, content, data, beforeClose, contentRef }) => {
  let modalContainer = document.createElement('div');
  modalContainer.setAttribute('id', 'modal_container');
  document.body.append(modalContainer);
  return new Vue({
    data () {
      return {
        dialogVisible: true,
        ...data,
      };
    },
    methods: {
      handleConfirm () {
        console.log('confirm');
        if (beforeClose) {
          beforeClose.call(this, 'confirm', this, this.handleClose);
        } else {
          this.handleClose();
        }
      },
      handleClose () {
        this.dialogVisible = false;
        // document.body.remove(modalContainer);
      },
    },
    // eslint-disable-next-line no-unused-vars
    render: function (h) {
      const contentComponent = content;
      const isComponent = content?.prototype instanceof Vue;
      const getContent = () => (typeof message === 'function' ? content.call(this, h, this) : <span>{content}</span>);
      const ret = this.dialogVisible ? (
        <el-dialog title={title} visible={true} width="30%" onBeforeClose={this.handleClose}>
          {isComponent ? <contentComponent ref={contentRef} /> : getContent()}
          <span slot="footer" class="dialog-footer">
            <el-button onClick={this.handleClose}>取 消</el-button>
            <el-button type="primary" onClick={this.handleConfirm}>
              确 定
            </el-button>
          </span>
        </el-dialog>
      ) : null;
      return ret;
    },
  }).$mount(modalContainer);
};

export { showModal, showModal2 };
