<template>
  <el-dialog :title="title"
    width="800px"
    :close-on-click-modal="false"
    :visible="true"
    @close="handleClose" >
    <div class="visible_bind_dialog">
      <vxe-grid
        ref="xTable"
        size="small"
        max-height="320px"
        border
        align="center"
        :toolbarConfig="{
          custom: false,
          slots: {
            buttons: 'toolbar_buttons'
          }
        }"
        auto-resize
        resizable
        :data="tableData"
        :columns="columns"
        :edit-config="{trigger: 'click', mode: 'cell'}">
        <template #toolbar_buttons>
          <el-button type="primary" @click="handleNew">新增</el-button>
        </template>
      </vxe-grid>
    </div>
    <span slot="footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import _ from 'lodash';
import Render from '@/components/form-generator/render/render.js';
import { getDefaultValueByTag } from '@/components/form-generator/utils/component.js';
export default {
  name: 'VisibleBindDialog',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Render,
  },
  props: {
    title: {
      type: String,
      default: '关联选项设置',
    },
    value: Array,
    sourceFieldList: {
      type: Array,
      default: ()=>[],
    },
    currentWidgetSchema: {
      type: Object,
      require: true,
    },
  },
  data () {
    return {
      tableData: _.cloneDeep(this.value) || [],
      columns: [
        {
          field: 'checkedValue',
          minWidth: '120px',
          title: '当选项为',
          slots: {
            default: ({ row }) => {
              return [
                <Render class="ml-1 mr-1 flex-fill"
                  formModel={this.getDefaultValue(row)}
                  conf={this.normalLizeConfig(row)}>
                </Render>,
              ];
            },
          },
        },
        {
          field: 'bindFields',
          minWidth: '200px',
          title: '显示以下控件',
          slots: {
            default: ({ row }) => {
              return [
                <el-select v-model={row.bindFields}
                  multiple
                  class="flex-fill"
                  popper-append-to-body={false}
                  size="small">
                  {
                    this.filteredBindFieldList.map(t=>(
                      <el-option
                        key={t.__config__.renderKey}
                        label={t.__config__.label}
                        value={t.__config__.renderKey}/>
                    ))
                  }
                </el-select>,

              ];
            },
          },
        },
        {
          field: 'action',
          minWidth: '80px',
          title: '操作',
          slots: {
            default: ({rowIndex }) => {
              return [
                <span class="cursor-pointer text-primary" onClick={ () => {
                  this.tableData.splice(rowIndex, 1);
                } }>刪除</span>,
              ];
            },
          },
        } ],
    };
  },
  methods: {
    getDefaultValue (i) {
      if (i.checkedValue === null || i.checkedValue === undefined) {
        i.checkedValue = getDefaultValueByTag(this.currentWidgetSchema);
      }
      return i;
    },
    normalLizeConfig (i) {
      let schema = _.cloneDeep(this.currentWidgetSchema);
      if (!schema) {
        // 若未匹配成功暂用span展示
        schema = {
          __config__: {
            tag: 'span',
          }};
      } else {
        const config = schema.__config__;
        schema.disabled = false;
        if (config.hasOwnProperty('filterParam')) {
          config.filterParam = null;
        }
        if (config?.tag === 'span') {
          schema.__config__.tag = 'el-input';
        } else if (config?.tag === 'el-date-picker' || config?.tag === 'el-time-picker') {
          schema = {
            __config__: config,
            style: {
              width: '100%',
            },
            type: 'daterange',
            defaultTime: ['00:00:00', '23:59:59'],
            valueFormat: 'timestamp',
            'range-separator': '至',
            'start-placeholder': '开始日期',
            'end-placeholder': '结束日期',
          };
        }
        config.renderKey = 'checkedValue';
        schema.placeholder = schema.placeholder || `${schema.__config__.tag === 'el-input' ? '请输入' : '请选择'}${schema.__config__.label}`;
      }
      return {
        ...schema,
        on: {
          input: val => {
            i.checkedValue = val;
          },
        },
      };
    },
    handleNew () {
      this.tableData.push({
        _key: `${new Date().getTime()}_${this.tableData.length}`,
        checkedValue: null,
        bindFields: [],
      });
    },
    handleClose () {
      this.$emit('close');
    },
    handleConfirm () {
      const checkValueIsEmptyOrNull = (t)=>{
        if (!t.checkedValue) {
          return true;
        }
        if (t.checkedValue instanceof Array) {
          return !t.checkedValue[0];
        } else {
          return Object.keys(t.checkedValue).length === 0;
        }
      };
      if (this.tableData.some(t=>checkValueIsEmptyOrNull(t) || !t.bindFields || !t.bindFields[0])) {
        this.$message.error('请补全关联选项设置');
        return;
      }
      const getItemHash = t=>{
        let valueHash = '';
        const tag = this.currentWidgetSchema.__config__.tag;
        if (tag === 'el-select') {
          valueHash = this.currentWidgetSchema.multiple ? t.checkedValue.map(t=>t.value).sort() : t.checkedValue.value;
        } else if (tag === 'el-checkbox-group') {
          valueHash = t.checkedValue.sort();
        } else if (tag === 'el-radio-group') {
          valueHash = t.checkedValue;
        } else {
          throw new Error(`${tag}未实现对应重复比对`);
        }
        const bindFieldHash = t.bindFields.sort();
        return JSON.stringify({valueHash, bindFieldHash});
      };

      // 设置hash计算规则，检测是否有重复项
      const dataHashList = this.tableData.map(t=>getItemHash(t));
      if (dataHashList.length > [...new Set(dataHashList)].length) {
        this.$message.error('存在重复设置项，请修改后重试');
        return;
      }
      this.$emit('input', this.tableData);
      this.handleClose();
    },
  },
  computed: {
    filteredBindFieldList () {
      return this.sourceFieldList.filter(t=>t.__config__?.renderKey !== this.currentWidgetSchema?.__config__?.renderKey && !t.__config__.isReservedField);
    },
  },
};
</script>

<style lang="scss" scoped>
  .visible_bind_dialog {
    overflow: hidden;
    &>div {
      overflow: auto;
      padding: 0 16px;
    }
  }
</style>
