<script>
import DepartmentSelector from '@/components/departMentSelector';
import mixin from './mixin';
export default {
  // 部门 系统组件
  name: 'CDepartmentSelector',
  mixins: [mixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: '请选择部门',
    },
  },
  components: {
    DepartmentSelector,
  },
  data () {
    return {
      departmentId: this.value?.id,
      departmentName: this.value?.name,
    };
  },
  watch: {
    value () {
      this.departmentId = this.value?.id;
      this.departmentName = this.value?.name;
    },
  },
  methods: {
    handleDepartmentChange (data) {
      this.departmentId = data.id;
      this.departmentName = data.name;
      this.$emit('input', data);
    },
  },
  render () {
    return (
      <department-selector
        size={this.inputSize}
        is-allow-leaf
        placeholder={this.placeholder}
        value={this.departmentId}
        name={this.departmentName}
        disabled={this.inputDisabled}
        onChange={this.handleDepartmentChange}></department-selector>
    );
  },
};
</script>
