import {
  inputComponents,
  selectComponents,
  layoutComponents,
  customComponents,
} from '@/components/form-generator/generator/config';
let formComponentMap = [];
[inputComponents, selectComponents, customComponents].forEach((m)=>{
  m.forEach(t=>{
    formComponentMap[t.__config__.tag] = t;
  });
});

export function getComponentType (item) {
  if (item.__config__?.componentName) {
    return item.__config__?.componentName;
  }
  if (item.__config__?.tag) {
    if (item.__config__.tag === 'el-date-picker') {
      return selectComponents.find(t=>t.__config__.tag === item.__config__.tag && t.type === item.type)?.__config__?.componentName || '';
    }
    if (item.__config__.tag === 'el-time-picker') {
      return selectComponents.find(t=>t.__config__.tag === item.__config__.tag && t['is-range'] === item['is-range'])?.__config__?.componentName || '';
    }
    return formComponentMap[item.__config__?.tag]?.__config__?.componentName || '';
  }
  if (item.__config__?.layout) {
    let layoutComponentItem = layoutComponents.find(t=>t.identifier(t) === t.identifier(item));
    return layoutComponentItem?.__config__?.componentName || '';
  }
  return '';
}
